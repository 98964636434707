let news_cat;
let insights_group;
let insights_theme;
let insights_label;

/* ready */
$(function () {
  'use strict';

  // NEWS
  $('.p-top-fv__news__cat li').on('click', function () {
    news_cat = $(this).attr('data-news-cat');

    if (!$(this).hasClass('is-current')) {
      $(this).addClass('is-current').siblings().removeClass('is-current');

      top_news_ajax();
    }
  });

  // INSIGHTS
  $('.p-top-insights__cat__list li[data-insights-group], .p-top-insights__cat__list li[data-insights-theme]').on('click', function () {
    insights_group = $(this).attr('data-insights-group');
    insights_theme = $(this).attr('data-insights-theme');

    if (!$(this).hasClass('is-current')) {
      $('.p-top-insights__cat__list li').removeClass('is-current');
      $(this).addClass('is-current');

      top_insights_ajax();
      top_insights_slide_ajax();
    }
  });

  $('select[name="insights-cat"]').on('change', function () {
    insights_label = $('select[name="insights-cat"] option:selected').parent().attr('label');

    if (!insights_label) {
      insights_group = $(this).val();
      insights_theme = $(this).val();
    } else {
      if (insights_label === '団体別') {
        insights_group = $(this).val();
        insights_theme = '';
      } else if (insights_label === 'テーマ別') {
        insights_group = '';
        insights_theme = $(this).val();
      }
    }

    top_insights_ajax();
    top_insights_slide_ajax();
  });
});

function top_news_ajax() {
  $.ajax({
    type: 'post',
    url: ajaxUrl, // functions.phpで指定した admin-ajax.php のURLが格納された変数
    cache: false,
    timeout: 3000,
    dataType: 'html',
    data: {
      'action': 'top_news_ajax', // 登録したアクション名
      'news-cat': news_cat // functions.phpでPOST値として渡す
    },
    success: function (data) {
      $('.p-top-fv__news__gt > div').fadeOut(200).fadeIn(200);
      setTimeout(function () {
        $('.p-top-fv__news__gt > div').html(data);
      }, 200);
    }
  });
}

function top_insights_slide_ajax() {
  $.ajax({
    type: 'post',
    url: ajaxUrl, // functions.phpで指定した admin-ajax.php のURLが格納された変数
    cache: false,
    timeout: 3000,
    dataType: 'html',
    data: {
      'action': 'top_insights_slide_ajax', // 登録したアクション名
      'insights-group': insights_group, // functions.phpでPOST値として渡す
      'insights-theme': insights_theme // functions.phpでPOST値として渡す
    },
    success: function (data) {
      $('.p-top-insights__col2').fadeOut(200).fadeIn(200);
      setTimeout(function () {
        $('.p-top-insights__col2').html(data);
        $('.p-top-insights__slide').slick();
      }, 200);
    }
  });
}

function top_insights_ajax() {
  $.ajax({
    type: 'post',
    url: ajaxUrl, // functions.phpで指定した admin-ajax.php のURLが格納された変数
    cache: false,
    timeout: 3000,
    dataType: 'html',
    data: {
      'action': 'top_insights_ajax', // 登録したアクション名
      'insights-group': insights_group, // functions.phpでPOST値として渡す
      'insights-theme': insights_theme // functions.phpでPOST値として渡す
    },
    success: function (data) {
      $('.p-top-insights__list').fadeOut(200).fadeIn(200);
      setTimeout(function () {
        $('.p-top-insights__list').html(data);
      }, 200);
    }
  });
}