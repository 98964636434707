/* ready */
$(function () {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function () {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });


  // hamburger
  $('.l-header__hum').on('click', function () {
    $(this).parents('.l-header').toggleClass('is-active');
  });

  gnaviCurrent($('.l-gnavi ul li'));

  // slick
  $('.c-slide').slick();

  // accordion
  $('.js-acc dt').on('click', function () {
    $(this).toggleClass('is-open').next().stop().slideToggle();
  });
});

// gnaviCurrent
function gnaviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function () {
    let link = $(this).children('a').attr('href');

    if (link == '/') {
      var matchLink = new RegExp('^' + link + '$');
    } else {
      var matchLink = new RegExp('^' + link);
    }

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}